import React from "react";
import {DatePicker, Spin, Typography} from "antd";
import moment from "moment";
import {ratePerArticle, ratePerView} from "../config/config";
import {getArticlesPath} from "../common/common";
import {collection, getDocs, getFirestore, query, where, collectionGroup, getCountFromServer} from "firebase/firestore";
import {getUserId} from "../firebase/firebase";

const { Title } = Typography;

export default class Salary extends React.Component {


    constructor(props) {
        super(props);
        this.state ={
            loading :true,
            numberOfArticles: 0,
            numberOfViews: 0
        }
    }

    componentDidMount = async () => {
        await this.getSalary(moment(new Date(), 'DD MMM, YYYY'), "garbageValue")
    }

    processArticleSalary = async (date) => {
        const chosenDate = moment(date);

        const recentArticlesSnapShot = await getDocs(query(collection(getFirestore(), getArticlesPath()),where("timestamp",">=",chosenDate.startOf("month").toDate()), where("timestamp","<=",chosenDate.endOf("month").toDate())));

        recentArticlesSnapShot.forEach(doc =>{
            console.log(doc.id);
        })
        let count = 0;
        recentArticlesSnapShot.forEach(doc => {
            count++;
        })
        this.setState({
            numberOfArticles: count
        });
    }

    processViewSalary = async (date) => {
        const chosenDate = moment(date);
        const viewsQuery = query(collectionGroup(getFirestore(), 'views'), where("writerId","==",getUserId()) ,where("timestamp",">=",chosenDate.startOf("month").toDate()), where("timestamp","<=",chosenDate.endOf("month").toDate()));
        const querySnapshot = await getCountFromServer(viewsQuery);
        this.setState({
            numberOfViews: querySnapshot.data().count
        });
    }

    getSalary = async (date, dateString) => {
        // console.log(date.toDate());

        this.setState({
            loading: true
        })

       const promises = [];
        promises.push(this.processArticleSalary(date));
        promises.push(this.processViewSalary());

        await Promise.all(promises);

        this.setState({
            loading:false
        });
    };

    render() {
        return (
            <div style={{alignContent:"center"}}>
            <Spin spinning={this.state.loading}>

                <div style={{alignContent:"center", textAlign:"center", marginTop:"30px"}}>
                    <DatePicker
                        defaultValue={moment(new Date(), 'DD MMM, YYYY')}
                        style={{width:"400px"}}
                        onChange={this.getSalary}
                        picker="month"
                    />
                </div>

                <div style={{margin:"50px", textAlign:"center"}}>
                    <Title style={{fontSize:100}}>₹ {
                        this.state.numberOfArticles * ratePerArticle + this.state.numberOfViews * ratePerView
                    }/-</Title>


                    <p>Number of articles published : {this.state.numberOfArticles}</p>
                    <p>Rate per article : ₹ {ratePerArticle}</p>

                    <p>Number of article views : {this.state.numberOfViews}</p>
                    <p>Rate per 1000 views : ₹ {Math.floor(ratePerView*1000)}</p>
                </div>

            </Spin>
        </div>);

    }
}