import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import {Component} from "react";

class App extends Component {

    render() {


        return (
            <Routes>
                <Route path="/" element={<Home/>}/>
                {/*<Route path="about" element={<About/>}/>*/}
            </Routes>
        );
    }
}


// function About() {
//     return (
//         <>
//             <main>
//                 <h2>Who are we?</h2>
//                 <p>
//                     That feels like an existential question, don't you
//                     think?
//                 </p>
//             </main>
//             <nav>
//                 <Link to="/">Home</Link>
//             </nav>
//         </>
//     );
// }

export default App;
