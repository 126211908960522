import {Component} from "react";
import {Button} from "antd";

export default class DeleteTrend extends Component {
    render() {
        return(
            <Button type="primary" danger onClick={() => {this.props.deleteArticle(this.props.article)}}>
                Delete
            </Button>
        )
    }
}