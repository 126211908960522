export const config = [
    "AIzaSyDsBAnA5utA-aFyV9zc1iIleauhVhBUnjs"
]

export const spreadSheetId = "1Vp8VN1wR37_NZwWu7a6AStibGxOY_TMu_BBXnBEL-Ms"

export const sheetFields = [
    "date" ,"articleName", "subHeading", "keywords", "embedCode" , "description", "isProcessed"
]

export const ratePerArticle = 3.5;

export const ratePerView = 0.2;

export const validUsers = ["misha", "tushar"]

export const blogCategories = [
    "Food",
    "Travel",
    "Health and fitness",
    "Lifestyle",
    "Fashion and beauty",
    "Photography",
    "Personal",
    "DIY craft",
    "Parenting",
    "Music",
    "Business",
    "Art and design",
    "Book and writing",
    "Personal finance",
    "Interior design",
    "Sports",
    "News",
    "Movie",
    "Religion",
    "Political",
    "Event",
    "Kids",
    "Cute",
    "Bollywood",
    "Actor",
    "Actress",
    "Professional",
    "Software"
]