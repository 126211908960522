import {Space, Table} from 'antd';
import React from 'react';
import DeleteTrend from "./DeleteTrend";
import CalculateArticleViews from "./CalculateArticleViews";
import PlagiarismReport from "./PlagiarismReport";


export default class ViewTrendTable extends React.Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Article Name',
                dataIndex: 'articleName',
                key: 'articleName',
                render: (text) => <p>{text}</p>,
            },
            {
                title: 'Sub Heading',
                dataIndex: 'subHeading',
                key: 'subHeading',
                // render: (text) => <p>{text}</p>,
                responsive: ['lg'],
                ellipsis: true
            },
            {
                title: 'Keywords',
                dataIndex: 'keywords',
                key: 'keywords',
                responsive: ['lg']
            },
            {
                title: 'Description',
                key: 'description',
                // dataIndex: 'description',
                render: (_, record) => {
                    console.log(record.description)
                    return (
                        <Space size="middle">
                            <div style={{display: "flex", justifyContent: "center"}} dangerouslySetInnerHTML={{__html: record.description}}/>
                        </Space>
                    );
                },
                ellipsis: true,
                responsive: ["lg"]
            },
            {
                title: 'Plagiarism',
                key: 'plagiarismPercentage',
                render: (_, record) => {
                    return (
                        <Space size="middle">
                            <PlagiarismReport articleDetails={record}/>

                        </Space>
                    );
                }
            },
            {
                title: 'Link',
                key: 'link',
                render: (_, record) => {
                    return (
                        <Space size="middle">
                            <a href={record.link} target="_blank" rel="noreferrer">Link</a>
                        </Space>
                    );
                }
            },
            {
                title: 'Current month views',
                key: 'views',
                render: (_, record) => {
                    return (
                        <Space size="middle">
                            <CalculateArticleViews article={record}/>
                        </Space>
                    );
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => {
                    if (!record.isProcessed) {
                        return (
                            <Space size="middle">
                                <DeleteTrend article={record} deleteArticle={this.props.deleteArticle}/>
                            </Space>
                        );
                    } else {
                        return <p>Already Processed</p>;
                    }
                }
            },
        ];
    }


    render() {
        return <Table size={"small"} columns={this.columns} dataSource={this.props.data}/>;
    }
}