import {Button} from "antd";
import {collection, getCountFromServer, getFirestore, query, where} from "firebase/firestore";
import {getArticlesPath} from "../common/common";
import React from 'react';
import moment from "moment/moment";

export default class CalculateArticleViews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            views: null
        }
    }

    calculateViews = async () => {
        let currentDate = moment()
        const coll = collection(getFirestore(),  getArticlesPath(), this.props.article.id , 'views');
        const query_ = query(coll, where("timestamp",">=",currentDate.startOf('month').toDate()), where("timestamp","<=" , currentDate.endOf('month').toDate()));
        const snapshot = await getCountFromServer(query_);
        console.log('count: ', snapshot.data().count);
        this.setState({
            views: snapshot.data().count
        })
    }

    render() {
        if (this.state.views != null) {
            return (
                <>
                    {/*<p>Current month views</p>*/}
                    <h1 style={{textAlign:"center"}}>{this.state.views}</h1>
                    <Button type="primary" onClick={this.calculateViews}>Refresh</Button>
                </>

            )
        }
        return (
            <>
                {/*<p>Current month views</p>*/}
                <Button type="primary" onClick={this.calculateViews}>Calculate</Button>
            </>

        )
    }
}