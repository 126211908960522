import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithPopup,signOut} from "firebase/auth";
import {addDoc, collection, getFirestore, serverTimestamp, setDoc, doc} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

/**
 * To find your Firebase config object:
 *
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */
const config = {
    apiKey: "AIzaSyBX5DaAPYs3GgcS5gxdlRfl3gYs0v2B6bk",
    authDomain: "crazytrendzzmain.firebaseapp.com",
    projectId: "crazytrendzzmain",
    storageBucket: "crazytrendzzmain.appspot.com",
    messagingSenderId: "492532869186",
    appId: "1:492532869186:web:ec292a6354bf255947a5db",
    measurementId: "G-H20SHS8FPX"
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error('No Firebase configuration object provided.\n Add your web app\'s configuration object to firebase-config.js');
    } else {
        return config;
    }
}

const firebaseAppConfig = getFirebaseConfig();
const app = initializeApp(firebaseAppConfig);
const db = getFirestore(app)
getAnalytics(app);

export const signIn = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(getAuth(), provider);
}

export const isLoggedIn = () => {
    return !!getAuth().currentUser;
}

export const getUserId = () => {
    return getAuth().currentUser.uid;
}

export const saveMessage = async (path, data, callback= null) => {
    try {
        // add timestamp to data
        data["timestamp"] =  serverTimestamp();
        await addDoc(collection(db, path), data);
        if(callback) callback();
    }
    catch(error) {
        console.error('Error writing article to Database', error);
        throw new Error(error);
    }
}


export const saveUserDetails = async () => {
    try {
        let currentUser = getAuth().currentUser;
        if(!currentUser) return;
        const dataToSave = {
            uid: currentUser.uid,
            "email": currentUser.email,
            "emailVerified": currentUser.emailVerified,
            "displayName": currentUser.displayName,
            "isAnonymous": currentUser.isAnonymous,
            "photoURL": currentUser.photoURL,
            "providerData": currentUser.providerData,
            "createdAt": currentUser.metadata.creationTime,
            "lastLoginAt": currentUser.metadata.lastSignInTime
        }
        await setDoc(doc(db, "users", currentUser.uid), dataToSave);
    }
    catch(error) {
        console.error('Error saving user to Database', error);
        throw new Error(error);
    }
}

export const signOutUser = async () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
        console.log("Signout succeeded");
    }).catch((error) => {
        console.log("Error while signing out " + error);
        // An error happened.
    });
}