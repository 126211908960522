import {Layout, Menu} from 'antd';
import React from 'react';
import AddTrend from "./AddTrend";
import ViewTrends from "./ViewTrends";
import MenuItem from "./MenuItem";
import Salary from "./Salary";
import Login from "./Login";
import {isLoggedIn, saveUserDetails, signOutUser} from "../firebase/firebase";
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import PageHeaderCustom from "../common/PageHeaderCustom";
import FooterCustom from "../common/FooterCustom";

const {Content, Sider} = Layout;
const addTrend = "Add Trend";
const viewTrends = "View Trends";
const salary = "salary";
const about = "About";
const signOut= "Logout";

const items = [
    {label: <MenuItem label={addTrend}/>, key: addTrend},
    {label: <MenuItem label={viewTrends}/>, key: viewTrends},
    {label: <MenuItem label={salary}/>, key: salary},
    {label: <a href="https://www.crazytrendzz.com/about" target="_blank" rel="noreferrer"><MenuItem label={about}/></a>, key:about},
    {label: <MenuItem label={signOut}/>, key: signOut}
];

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleContent: addTrend
        }
    }

    componentDidMount() {
        console.log("Version1.0")
        onAuthStateChanged(getAuth(), async () => {
            this.setState({});
            await saveUserDetails()
        });
    }

    handleMenuItemClick = async ({key}) => {
        if(key === about) return
        if(key === signOut){
           await signOutUser();
        }
        this.setState({
            visibleContent: key
        })
    }

    render = () => {
        const shouldAddTrendShow = this.state.visibleContent === addTrend;
        const shouldViewTrendsShow = this.state.visibleContent === viewTrends;
        const shouldSalaryShow = this.state.visibleContent === salary;

        if (isLoggedIn()) {
            return (<Layout>
                <PageHeaderCustom/>

                <Layout>
                    <Sider collapsible breakpoint="md" collapsedWidth={0}
                           zeroWidthTriggerStyle={{position: "absolute", top: 0}}
                           style={{backgroundColor:"black"}}
                    >
                        <Menu
                            style={{minHeight:"780px", backgroundColor: "black"}}
                            theme="dark"
                            defaultSelectedKeys={[addTrend]}
                            items={items}
                            onClick={this.handleMenuItemClick}
                        />
                    </Sider>
                    <Content>
                        {shouldAddTrendShow ? <AddTrend/> : null}
                        {shouldViewTrendsShow ? <ViewTrends/> : null}
                        {shouldSalaryShow ? <Salary/> : null}
                    </Content>
                </Layout>

<FooterCustom/>

            </Layout>);
        } else {
            return <Login/>;
        }
    }
}
