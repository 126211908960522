import React from 'react';
import GoogleButton from 'react-google-button'
import {signIn} from "../firebase/firebase";
import PageHeaderCustom from "../common/PageHeaderCustom";
import {Button} from "antd";
import FooterCustom from "../common/FooterCustom";
import "../css/login.css"

class Login extends React.Component {

    render() {
        return (
            <div style={{width: "100%"}}>
                <PageHeaderCustom/>
                <div className="content">
                    <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <GoogleButton
                                onClick={signIn}
                                style={{
                                    margin: "50px"
                                }}
                            />
                        </div>
                        <div className="flex-container">
                            <div style={{textAlign: "center"}}><a href="https://www.crazytrendzz.com/about"
                                                                  target="_blank" rel="noreferrer"><Button
                                className="button-gap">Earn with us</Button></a></div>

                            <div style={{textAlign: "center"}}><a href="https://www.crazytrendzz.com/about"
                                                                  target="_blank" rel="noreferrer"><Button
                                className="button-gap">About us</Button></a></div>
                            <div style={{textAlign: "center"}}><a href="https://www.crazytrendzz.com/contact"
                                                                  target="_blank" rel="noreferrer"><Button
                                className="button-gap">Contact us</Button></a></div>
                        </div>
                    </div>
                    <p className="lets-write">Let's Write & Earn</p>
                    <p className="lets-write">Write once & Earn Lifetime</p>
                </div>


                <FooterCustom/>
            </div>
        );
    }
}

export default Login;