import {Button, Space} from "antd";
import React from "react";
import "../css/footer.css";

export default class FooterCustom extends React.Component {
    render() {
        return (
            <div>
                <div style={{height:"100px"}}></div>
            <footer className="footer" style={{
                padding: "2rem 0",
                borderTop: "1px solid #eaeaea",
                marginTop: "10px",
                width:"100%"
            }}>
                <Space wrap style={{display:"flex", justifyContent:"center"}}>
                    <a href="https://www.crazytrendzz.com/disclaimer" target="_blank"
                       rel="noreferrer"><Button>Disclaimer</Button></a>
                    <a href="https://www.crazytrendzz.com/termsAndConditions" target="_blank" rel="noreferrer"><Button>Terms
                        and Conditions</Button></a>
                    <a href="https://www.crazytrendzz.com/about" target="_blank" rel="noreferrer"><Button>About
                        us</Button></a>
                    <a href="https://www.crazytrendzz.com/privacyPolicy" target="_blank" rel="noreferrer"><Button>Privacy
                        Policy</Button></a>
                    <a href="https://www.crazytrendzz.com/contact" target="_blank" rel="noreferrer"><Button>Contact
                        us</Button></a>
                </Space>
            </footer>
            </div>
        );
    }
}