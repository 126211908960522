import React from "react";
import {DatePicker, Spin} from "antd";
import moment from "moment";
import ViewTrendTable from "./ViewTrendTable";
import {collection, deleteDoc, getDocs, getFirestore, orderBy, query, where} from 'firebase/firestore';
import {getArticlesPath, getWebsiteUrl} from "../common/common";
import {getStorage,deleteObject,ref} from "firebase/storage";
import {getUserId} from "../firebase/firebase";


export default class ViewTrends extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: moment(new Date())
        }
    }

    componentDidMount = async () => {
        await this.onChange(moment(new Date(), 'DD MMM, YYYY'), "garbageValue");
    }

    deleteArticle = async (article) => {
        // console.log("Delete article request received for ")
        // console.log(article);
        // console.log("image: " + article.image);
        // if image is present , delete it
        if(article.image){
            const imageRef = await ref(getStorage(), article.image);
            await deleteObject(imageRef);
        }
        // delete the article
        await deleteDoc(article.ref);
        await this.onChange(this.state.date, "garbageValue");
    }

    onChange = async (date, dateString) => {
        this.setState({
            data: [], loading: true, date: date
        });

        let currentDate = moment(date)
        // console.log(date.startOf('day').toDate())
        // console.log(currentDate.toDate())

        const articles = [];

        // Create the query to load
        const recentArticlesSnapShot = await getDocs(query(collection(getFirestore(), getArticlesPath()), where("timestamp",">",currentDate.startOf('day').toDate()), where("timestamp","<" , currentDate.startOf('day').add(1,"days").toDate()) ,orderBy('timestamp', 'desc')));
        recentArticlesSnapShot.forEach(doc => {
            const article = doc.data();
            article.ref = doc.ref;
            article.id = doc.id;
            article.key = doc.id;
            article.link = `${getWebsiteUrl()}/articles/${getUserId()}/${doc.id}`
            articles.push(article);
        })

        this.setState({
            data: articles, loading: false
        });
    };

    render() {
        return (<div style={{alignContent: "center"}}>
            <Spin spinning={this.state.loading}>

                <div style={{alignContent: "center", textAlign: "center", marginTop: "30px"}}>
                    <DatePicker
                        defaultValue={moment(new Date(), 'DD MMM, YYYY')}
                        style={{width: "400px"}}
                        onChange={this.onChange}
                    />
                </div>

                <div style={{margin: "10px"}}>
                    <ViewTrendTable data={this.state.data} deleteArticle={this.deleteArticle}/>
                </div>

            </Spin>
        </div>);

    }
}