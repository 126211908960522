import {Button, Form, Input, message, Select, Spin, Upload} from 'antd';
import React from 'react';
import {blogCategories} from "../config/config";
import moment from "moment/moment";
import {getUserId, saveMessage} from "../firebase/firebase";
import {getArticlesPath} from "../common/common";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../css/addTrend.css"

const {Option} = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

class AddTrend extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
            editorState: EditorState.createEmpty(),
            showRichTextEditor: false,
            textCount: 0
        }
    }

    setFileList = async (fileList) => {
        this.setState({fileList})


    }

    onChange = ({fileList: newFileList}) => {
        this.setFileList(newFileList);
    };

    onChangeI = (e) => {
        this.setState({
            textCount:e.target.value.replace(/\s+/g, ' ').trim().length
        })
    }

    onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    convert = (html) => {
        const divContainer= document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            textCount: this.convert(draftToHtml(convertToRaw(editorState.getCurrentContent()))).replace(/\s+/g, ' ').trim().length
        });
    };

    switchTextEditor = () => {
        let currentEditorstate = this.state.showRichTextEditor;
        this.setState({
            showRichTextEditor: !currentEditorstate
        })
    }

    showErrorMessage = async (content) => {
        await message.error({
            content,
            style: {marginTop: "120px"}
        });
    }

    onFinish = async ({embedCode, keywords, articleName, subHeading, description, categories}) => {

        // override description if rich text editor is used
        if(this.state.showRichTextEditor){
            description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
            if(this.state.textCount < 800) {
                await this.showErrorMessage("Article content should not be less than 800 characters");
                return;
            }
        } else {
            // Replace new lines with br tag in description
            description = description.split("\n").join("<br/>");
        }

        if (!embedCode && this.state.fileList.length === 0) {
            await this.showErrorMessage("one of the embedCode or image needs to be present")
            return;
        }

        this.setState({
            loading: true
        });

        let publicImageUrl = null;
        let newImageRef = null;

        // upload image
        if (this.state.fileList.length > 0) {
            const filePath = `${getUserId()}/${Date.now() + this.state.fileList[0].name}`;
            newImageRef = ref(getStorage(), filePath);
            await uploadBytesResumable(newImageRef, this.state.fileList[0].originFileObj);
            publicImageUrl = await getDownloadURL(newImageRef);
        }


        try {
            await saveMessage(getArticlesPath(), {
                date: moment().format("DD-MM-YY"),
                embedCode: embedCode ? embedCode : null,
                image: publicImageUrl,
                keywords,
                articleName,
                subHeading: subHeading ? subHeading : null,
                description,
                categories,
                isProcessed: false,
                writerId: getUserId(),
                isPlagiarismChecked: false
            });

            message.info({
                content: "Your Article is submitted",
                style: {marginTop: "120px"}
            });

            this.formRef.current.resetFields();
            this.setState({
                fileList: [],
                editorState: EditorState.createEmpty(),
                textCount: 0
            });

        } catch (e) {
            // article submission has failed due to a error
            message.error({
                content: `Your Article is not submitted due to an error. Please send photo to developer. Error: ${e.message} `,
                style: {marginTop: "120px"}
            });
            // clean uploaded image if any
            if (newImageRef) {
                await deleteObject(newImageRef);
            }
        } finally {
            this.setState({
                loading: false
            });
        }


    };


    render() {

        const blogCategoryOptions = [];
        for (const category of blogCategories) {
            blogCategoryOptions.push(<Option key={category}>{category}</Option>);
        }

        return (
            <div style={{margin: "5px", marginTop: "50px"}}>
                <Spin spinning={this.state.loading}>

                    <Form {...layout} ref={this.formRef} name="control-hooks" onFinish={this.onFinish}>

                        {/*<Form.Item*/}
                        {/*    name="embedCode"*/}
                        {/*    label="Embed Code"*/}
                        {/*>*/}
                        {/*    <Input autoComplete="off"*/}
                        {/*           placeholder="(Optional) Please enter embed code of any post/video, you want to write about. The post/video will be embedded in your article"/>*/}
                        {/*</Form.Item>*/}

                        {/*<a href={"https://www.youtube.com/watch?v=FAY1K2aUg5g"} target="_blank" rel="noreferrer"><p style={{textAlign:"center"}}>How to get embed code of youtube video on desktop</p></a>*/}
                        {/*<a href={"https://www.youtube.com/watch?v=zlt3ZYEk3lc"} target="_blank" rel="noreferrer"><p style={{textAlign:"center"}}>How to get embed code of youtube video on mobile</p></a>*/}
                        {/*<a href={"https://www.youtube.com/watch?v=9CxpMEujXIs"} target="_blank" rel="noreferrer"><p style={{textAlign:"center"}}>How to get embed code of instagram</p></a>*/}

                        <Form.Item
                            label="Upload Image"
                        >
                            <Upload
                                beforeUpload={async (file) => {
                                    await this.setFileList([...this.state.fileList, file]);
                                    return false;
                                }}
                                listType="picture-card"
                                fileList={this.state.fileList}
                                onChange={this.onChange}
                                onPreview={this.onPreview}
                            >
                                {this.state.fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="articleName"
                            label="Title / Article Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Please enter an attractive article name"/>
                        </Form.Item>

                        <Form.Item
                            name="subHeading"
                            label="Sub Heading"
                        >
                            <Input autoComplete="off"
                                   placeholder="Optional subheading to describe article in few words"/>
                        </Form.Item>

                        {
                            this.state.showRichTextEditor ?
                                <div>
                                    <h2 style={{textAlign:"center"}}>Article Content</h2>
                                    <div style={{textAlign: "center"}}><Button type="primary"
                                                                               onClick={this.switchTextEditor}>Switch to
                                        old text editor</Button></div>

                                    <div className="blogEditorWrapper">

                                        <div className="blogEditor">
                                            <Editor
                                                editorState={this.state.editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>

                                    </div>
                                    <p style={{textAlign:"center"}}> Character count: { this.state.textCount}</p>
                                </div>
                                :
                                <div>
                                    <div style={{textAlign: "center"}}><Button type="primary"
                                                                               onClick={this.switchTextEditor}>Switch to
                                        rich text editor</Button></div>
                                    <Form.Item
                                        name="description"
                                        label="Article Content"
                                        rules={[
                                            {
                                                required: true,
                                                type: "string"
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            onChange={this.onChangeI}
                                            style={{whiteSpace: "pre-wrap"}}
                                            autoComplete="off"
                                            autoSize={{minRows: 3, maxRows: 20}}
                                            showCount
                                            placeholder="Please write a short description in your own words. Do not copy paste from anywhere. Read from anywhere but write in your own words"/>
                                    </Form.Item>
                                    <p style={{textAlign:"center"}}> Character count: { this.state.textCount}</p>
                                </div>

                        }


                        <Form.Item
                            name="keywords"
                            label="Keywords"
                            rules={[
                                {
                                    required: true
                                },
                            ]}
                        >
                            <Input autoComplete="off"
                                   placeholder="Please enter comma separated keywords with which users tend to search your article"/>
                        </Form.Item>

                        <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                                {
                                    required: true
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                size="medium"
                                placeholder="Please select"
                                style={{
                                    width: '100%',
                                }}
                            >
                                {blogCategoryOptions}
                            </Select>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                    {/*<textarea*/}
                    {/*    disabled*/}
                    {/*    value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}*/}
                    {/*/>*/}
                    {/*<p style={{textAlign: "center", fontSize: "40px", marginTop: "40px"}}>Embed options coming*/}
                    {/*    soon..</p>*/}
                </Spin>
            </div>
        );
    }
}

export default AddTrend;