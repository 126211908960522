import React from "react";
import {Button, Modal, Spin} from "antd";
import {getFirestore} from "firebase/firestore";
import {doc, getDoc} from "firebase/firestore";

export default class PlagiarismReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            plagiarismReport: null,
            isModalOpen: false
        }
    }

    handleOk = () => {
        this.setState({isModalOpen: false});
    };

    handleCancel = () => {
        this.setState({isModalOpen: false});
    };

    fetchPlagiarismReport = async () => {

        this.setState({
            isModalOpen: true
        });

        if (this.state.plagiarismReport == null) {
            const docRef = doc(getFirestore(), "plagiarismReports", this.props.articleDetails.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                this.setState({
                    plagiarismReport: docSnap.data()
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    }

    render() {
        return (<div>
            <Modal title="Plagiarism Report" open={this.state.isModalOpen} onOk={this.handleOk}
                   onCancel={this.handleCancel}>
                <p style={{color:"red"}}>Articles having plagiarism > 20% will not be published on main page and won't appear in google search results</p>


                {
                    this.state.plagiarismReport ? <div>{
                        this.state.plagiarismReport.sources.map(source => {
                            return (
                                <div key={source.url} style={{border:"1px solid", margin:"10px", padding:"5px"}}>
                                    <p><b>Copied from Url </b></p>

                                    <a href={source.url}>{source.url}</a>
                                    <br/>
                                    <p>
                                        <br/>
                                        <p><b>Text Matches</b></p>

                                        {source.matches.map(match => {
                                            return <p key={match.matchText}>{match.matchText}</p>
                                        })}
                                    </p>
                                </div>
                            );
                        })

                    }</div> : <Spin size="large"/>
                }

            </Modal>
            <p>{this.props.articleDetails.plagiarismPercentage}%</p>
            <Button type="primary" onClick={this.fetchPlagiarismReport}>View Report</Button>
        </div>);
    }
}