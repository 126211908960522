import React from "react";
import {PageHeader} from "antd";
export default class PageHeaderCustom extends React.Component {
    render(){
        return (
            <PageHeader
                style={{backgroundColor: "black"}}
                className="site-page-header-responsive"
                title={<p style={{color: "whitesmoke"}}>Crazy Trendzz</p>}
                extra={[
                    <p key="smallDesc" style={{color: "whitesmoke"}}>All about news and entertaining trends</p>
                ]}
            />
        );
    }
}