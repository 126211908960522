import React from "react";
export default class MenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div style={{color: "white"}}>{this.props.label}</div>
            </div>
        );
    }


}